<template>
    <div v-if="isOpen" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div class="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start w-full">
              <div class="mt-3 text-center w-full sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Feedback
                </h3>
                <p class="text-sm text-gray-500">No personal info will be transfered or stored anywhere. This message is anonymous</p>
                <div class="mt-2 w-full">
                  <textarea v-model="feedback" rows="7" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Amazing app! You guys are the best. I wish it also could..." required></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button type="submit" 
                class="inline-flex justify-center w-full border border-transparent px-6 py-3 bg-[#0000ff] text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5" 
                :disabled="isLoading || !isValidFeedback" 
                :class="{ 'opacity-50 cursor-not-allowed': isLoading || !isValidFeedback }"
                @click="submitFeedback">
                Submit
              </button>
            </span>
            <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button class="inline-flex justify-center w-full border border-gray-300 px-6 py-3 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                @click="closeModal">
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { signInAnonymously } from "firebase/auth"
  export default {
    props: ['parentName', 'isOpen',],
    emits: ['close',], 
    data() {
      return {
        feedback: '',
        isLoading: false,
        message: '',
        idToken: null,
      };
    },
    methods: {
      async submitFeedback() {
        this.isLoading = true
        this.message = ''
  
        try {
          await signInAnonymously(this.$firebase.authFW).then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log(user)
            const response = await fetch('https://savefeedback-6su6flo5kq-nw.a.run.app', {
              method: 'POST',
              headers: {
                // 'Authorization': `Bearer ${this.idToken}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ feedback: this.feedback, service: this.parentName, url: window.location.href, })//user: user.uid })
            })
    
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
    
            this.message = 'Feedback saved successfully'
            this.feedback = ''
          }).catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            this.message = errorMessage
            console.log(errorCode, errorMessage)
          });
          
        } catch (error) {
          this.message = 'An error occurred. Please try again.'
        } finally {
          this.isLoading = false
          this.closeModal
        }
      },
      closeModal() {
        this.feedback = ''
        this.message = ''
        this.$emit('close')
      }
    },
    computed: {
      isValidFeedback() {
        return this.feedback.trim().length > 0;
      },
    },
  }
  </script>