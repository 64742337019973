<template>
  <div class="relative flex items-top justify-center min-h-screen bg-gray-100 sm:pt-0">
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex gap-2 flex-wrap">
        <svg class="m-auto" width="305" height="154" xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 256 1024.000000 512.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" fill="blue" stroke="none">
            <path d="M7970 6985 c-63 -8 -162 -23 -220 -35 -58 -12 -121 -25 -140 -29 -56 -12 -270 -85 -375 -128 -62 -26 -278 -131 -330 -161 -158 -91 -341 -212 -462 -305 -22 -18 -65 -51 -95 -73 -157 -121 -455 -411 -603 -588 -22 -27 -67 -80 -100 -120 -33 -39 -67 -81 -75 -92 -8 -12 -40 -55 -71 -95 -70 -92 -210 -291 -270 -384 -80 -122 -235 -363 -274 -425 -177 -281 -357 -533 -488 -683 -111 -127 -221 -221 -299 -255 -125 -53 -280 7 -459 180 -84 80 -199 206 -249 272 -22 28 -62 80 -90 116 -29 36 -66 83 -83 106 -128 166 -169 216 -281 346 -157 182 -249 276 -397 399 -192 161 -325 239 -510 301 -132 45 -178 55 -314 69 -332 34 -723 -74 -1114 -310 -96 -58 -118 -73 -247 -165 -84 -60 -238 -193 -345 -297 l-79 -76 2 -263 3 -263 92 109 c446 532 1025 881 1514 910 327 20 600 -105 914 -420 66 -67 165 -175 220 -242 106 -128 112 -136 286 -369 184 -246 257 -333 399 -476 137 -138 173 -167 290 -235 177 -104 372 -131 550 -76 62 18 147 63 240 125 51 34 268 252 327 328 148 189 186 241 287 394 113 171 124 188 226 349 300 474 498 750 714 1001 126 145 380 391 522 505 506 406 968 610 1476 652 127 10 173 10 330 -5 172 -16 352 -47 478 -82 30 -8 75 -20 100 -25 49 -11 383 -125 465 -158 171 -69 247 -102 404 -179 97 -47 224 -112 282 -144 58 -32 109 -59 112 -59 4 0 7 108 7 239 l0 239 -172 85 c-147 72 -223 107 -363 167 -388 167 -829 288 -1183 324 -178 19 -390 19 -552 1z"/>
          </g>
        </svg>
      </div>
      <div class="py-8">
        <h1 class="text-2xl sm:text-4xl font-bold text-center mb-2">Mikk_the_Name_Maker</h1>
        <p class="text-center text-gray-500">Fill in the fields and copy the result to the clipboard</p>
      </div>
      <div>
        <div v-if="isLoading">
          <div class="animate-pulse flex space-x-4">
            <div class="flex-1 space-y-4 py-1">
              <div class="h-4 bg-gray-400 rounded w-3/4"></div>
              <div class="space-y-2">
                <div class="h-4 bg-gray-400 rounded"></div>
                <div class="h-4 bg-gray-400 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!isEditing" class="flex gap-2 flex-wrap">
          <select v-if="forms" class="flex-grow shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full sm:w-auto" v-model="selectedFormId">
            <option v-for="form in forms" :key="form.id" :value="form.id">
              {{ form.title }}
            </option>
          </select>
          <button class="px-6 py-3 bg-[#0000ff] text-white ms-auto hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue " @click="newForm">New</button>
          <button class="px-6 py-3 bg-[#0000ff] text-white hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue " @click="editForm">Edit</button>
        </div>
        <div v-else class="flex gap-2 flex-wrap">
          <input type="text" class="flex-grow shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full sm:w-auto animate-[bounce_.3s_ease-out_1.5]" v-model="currentForm.title" />
          <button :class='isSaving ? "bg-blue-400" : "bg-[#0000ff]"' class="px-6 py-3 text-white ms-auto hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue " @click="saveForm" :disabled="isSaving">{{ isSaving ? 'Saving!' : 'Save' }}</button>
          <button :class='isDeleting ? "bg-red-400" : "bg-[#ff0000]"' class="px-6 py-3 text-white hover:bg-red-400 focus:outline-none focus:border-red-700 focus:shadow-outline-red " @click="deleteForm" :disabled="isDeleting">{{isDeleting ? 'Deleting!' : 'Delete' }}</button>
          <button class="px-6 py-3 bg-[#0000ff] text-white hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue " @click="cancelChanges">Cancel</button>
        </div>
        
      </div>
      <DynamicFields :fields="currentForm.fields" :isEditing="isEditing" @add-field="addField" @delete-field="deleteField" @move-field="moveField"/>
    </div>
    <div class="flex text-sm text-gray-500 absolute bottom-2 left-2 cursor-pointer">
      <span class="mr-2" @click="showFeedbackModal = true">Leave feedback</span>
    </div>
    <div class="flex text-sm text-gray-500 absolute bottom-2 right-2">
      <span>
        <i>
          <svg class="m-auto" width="32" height="16" xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 256 1024.000000 512.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" fill="blue" stroke="none">
              <path d="M7970 6985 c-63 -8 -162 -23 -220 -35 -58 -12 -121 -25 -140 -29 -56 -12 -270 -85 -375 -128 -62 -26 -278 -131 -330 -161 -158 -91 -341 -212 -462 -305 -22 -18 -65 -51 -95 -73 -157 -121 -455 -411 -603 -588 -22 -27 -67 -80 -100 -120 -33 -39 -67 -81 -75 -92 -8 -12 -40 -55 -71 -95 -70 -92 -210 -291 -270 -384 -80 -122 -235 -363 -274 -425 -177 -281 -357 -533 -488 -683 -111 -127 -221 -221 -299 -255 -125 -53 -280 7 -459 180 -84 80 -199 206 -249 272 -22 28 -62 80 -90 116 -29 36 -66 83 -83 106 -128 166 -169 216 -281 346 -157 182 -249 276 -397 399 -192 161 -325 239 -510 301 -132 45 -178 55 -314 69 -332 34 -723 -74 -1114 -310 -96 -58 -118 -73 -247 -165 -84 -60 -238 -193 -345 -297 l-79 -76 2 -263 3 -263 92 109 c446 532 1025 881 1514 910 327 20 600 -105 914 -420 66 -67 165 -175 220 -242 106 -128 112 -136 286 -369 184 -246 257 -333 399 -476 137 -138 173 -167 290 -235 177 -104 372 -131 550 -76 62 18 147 63 240 125 51 34 268 252 327 328 148 189 186 241 287 394 113 171 124 188 226 349 300 474 498 750 714 1001 126 145 380 391 522 505 506 406 968 610 1476 652 127 10 173 10 330 -5 172 -16 352 -47 478 -82 30 -8 75 -20 100 -25 49 -11 383 -125 465 -158 171 -69 247 -102 404 -179 97 -47 224 -112 282 -144 58 -32 109 -59 112 -59 4 0 7 108 7 239 l0 239 -172 85 c-147 72 -223 107 -363 167 -388 167 -829 288 -1183 324 -178 19 -390 19 -552 1z"/>
            </g>
          </svg>
        </i>
        by MAPai
      </span>
    </div>
    <FeedbackWhale :parent-name="$options.name" :is-open="showFeedbackModal" @close="this.showFeedbackModal = false"/>
  </div>
</template>

<script>
import DynamicFields from './components/DynamicFields.vue'
import FeedbackWhale from './components/FeedbackWhale.vue'
import { signInWithEmailAndPassword } from "firebase/auth"

export default {
  name: 'Mikk_the_Name_Maker',
  components: {
    DynamicFields,
    FeedbackWhale,
  },
  data() {
    return {
      forms: [],
      currentForm: {
        title: 'New Form',
        fields: [],
        id: null
      },
      selectedFormId: null,
      formBeforeEdit: null,
      isDemo: true,
      isLoading: true,
      isEditing: false,
      isCreating: false,
      isSaving: false,
      isSaved: false,
      isDeleting: false,
      idToken: null,
      showFeedbackModal: false,
    }
  },
  methods: {
    async getForms(init = true) {
    // Make a GET request to the Cloud Function
    const response = await fetch('https://europe-west2-map-demo-circa23.cloudfunctions.net/getFormData', {
      headers: {
        'Authorization': `Bearer ${this.idToken}`
      }
    })
    // Check the response
    if (response.ok) {
      const forms = await response.json();
      this.forms = forms;
      if (init) this.selectedFormId = this.forms[0].id;
      this.isLoading = false;
    } else {
      console.error('Failed to fetch form data');
    }
  },
    editForm() {
      if (this.currentForm) {
        this.resetStatus()
        this.isEditing = true
        this.formBeforeEdit = JSON.parse(JSON.stringify(this.currentForm))
      }
    },
    async saveForm() {
      if (this.currentForm) {
        this.isSaving = true
        // Create a copy of the current form
        const formToSave = JSON.parse(JSON.stringify(this.currentForm));

        // Remove the field values
        formToSave.fields.forEach(field => {
          delete field.value;
        });

        // Before saving, convert the options string to an array
        formToSave.fields.forEach(field => {
          if (field.type === 'select' && typeof field.options === 'string') {
            field.options = field.options.split(/[\n,]+/).map(option => option.trim());
          }
        });

        // Determine the HTTP method based on whether formToSave.id exists
        const httpMethod = formToSave.id ? 'PUT' : 'POST';

        // Make a POST request to the Cloud Function
        const response = await fetch('https://europe-west2-map-demo-circa23.cloudfunctions.net/saveFormData', {
          method: httpMethod,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.idToken}`
          },
          body: JSON.stringify(formToSave)
        })
        // Check the response
        if (response.ok) {
          const savedForm = await response.json()
          console.log(savedForm)  // "success"
          await this.getForms(false);
          this.selectedFormId = savedForm.id
          this.resetStatus()
        } else {
          console.error('Failed to save form data')
        }
      }
    },
    async deleteForm() {
      if (this.currentForm) {
        const confirmed = confirm(`Are you sure you want to delete form ${this.currentForm.title}?`);
        if (!confirmed) {
          return;
        }
        this.isDeleting = true
        // Make a DELETE request to the Cloud Function
        const response = await fetch('https://europe-west2-map-demo-circa23.cloudfunctions.net/deleteFormData', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.idToken}`
          },
          body: JSON.stringify({ id: this.currentForm.id })
        })
        // Check the response
        if (response.ok) {
          const data = await response.json()
          console.log(data.status)  // "success"
          // Refresh the forms
          await this.getForms();
          this.resetStatus()
        } else {
          console.error('Failed to delete form data')
        }
      }
    },
    newForm() {
      this.resetStatus()
      this.isEditing = true
      this.isCreating = true
      this.currentForm = {
        title: 'New Form',
        fields: [],
        id: null
      }
    },
    addField(newField) {
      this.currentForm.fields.push(newField);
    },
    deleteField(index) {
      this.currentForm.fields.splice(index, 1);
    },
    moveField(move) {
      const newIndex = move.index + move.direction;
      if (newIndex >= 0 && newIndex < this.currentForm.fields.length) {
        const fields = [...this.currentForm.fields];
        const field = fields[move.index];
        fields.splice(move.index, 1);
        fields.splice(newIndex, 0, field);
        this.currentForm.fields = fields;
      }
    },
    cancelChanges() {
      this.currentForm = this.formBeforeEdit || this.forms[0] || {
        title: 'New Form',
        fields: [],
        id: null
      }
      this.selectedFormId = this.currentForm.id
      this.formBeforeEdit = null  // Reset formBeforeEdit
      this.resetStatus()
    },
    resetStatus() {
      this.isSaved = false
      this.isSaving = false
      this.isEditing = false
      this.isDeleting = false
      this.isCreating = false
    },
  },
  watch: {
    selectedFormId(newId) {
      this.currentForm = this.forms.find(form => form.id === newId);
    },
  },
  async mounted() {
    this.resetStatus()
    if (!this.isDemo) {
      // Get the ID token
      this.idToken = await this.$firebase.authMTNM.currentUser.getIdToken()
    } else {
      // Sign in the demo user
      const demoUser = await signInWithEmailAndPassword(this.$firebase.authMTNM, 'demo@mapai.app', 'demopassword23')
      // Get the ID token
      this.idToken = await demoUser.user.getIdToken()
    }
    // Fetch the forms
    await this.getForms();
    this.currentForm = this.forms[0]
  },
}
</script>