// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigMTNM = {
  apiKey: "AIzaSyApmm6R9el1xgYgof4VfDHEfAUoV46Kkig",
  authDomain: "map-demo-circa23.firebaseapp.com",
  projectId: "map-demo-circa23",
  storageBucket: "map-demo-circa23.appspot.com",
  messagingSenderId: "468033795978",
  appId: "1:468033795978:web:760a42a68fe5986be47a73"
}

const firebaseConfigFW = {
  apiKey: "AIzaSyAW2i3WZtyYBKg30xVXzvZBuqDpCMX__zU",
  authDomain: "map-feedback.firebaseapp.com",
  projectId: "map-feedback",
  storageBucket: "map-feedback.appspot.com",
  messagingSenderId: "762395370028",
  appId: "1:762395370028:web:42adae4f8cb5470afba789"
}

// Initialize Firebase
const firebaseAppMTNM = initializeApp(firebaseConfigMTNM)
const firebaseAppFW = initializeApp(firebaseConfigFW, "FW")

// Export the initialized Firebase app
export default {firebaseAppMTNM, firebaseAppFW}