<template>
  <div class="mt-14 mb-6 dynamic-fields">
    <div v-for="(field, index) in fields" :key="index">
      <div v-if="isEditing" class="flex items-start mb-4 flex-wrap gap-2 justify-between w-full">
        <div class="me-auto w-1/2 sm:w-20 order-1">
          <button class="w-10 h-10 bg-[#0000ff] hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue text-white" @click="$emit('move-field', { index, direction: -1 })">↑</button>
          <button class="w-10 h-10 bg-[#0000ff] hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue text-white" @click="$emit('move-field', { index, direction: 1 })">↓</button>
        </div>
        <input v-model="field.label" class="grow shadow appearance-none border rounded block py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline order-3 sm:order-2" placeholder="Label" />
        <select v-model="field.type" class="grow shadow border rounded block py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline order-4 sm:order-3" @change="resetError(field)">
          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="textarea">Textarea</option>
          <option value="select">Select</option>
          <option value="boolean">Boolean</option>
        </select>
        <button class="ms-auto w-10 h-10 bg-[#ff0000] hover:bg-red-400 focus:outline-none focus:red-blue-700 focus:shadow-outline-red text-white order-2 sm:order-4" @click="this.$emit('delete-field', index);">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 mx-auto">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <div v-if="field.type === 'select'" class="flex w-full flex-wrap order-5">
          <label class="block text-gray-700 text-sm font-bold me-2 mb-2 sm:ms-auto">Options</label>
          <textarea v-model="field.options" class="shadow appearance-none border rounded block py-2 px-3 w-full sm:w-1/2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Option 1,Option 2,Option 3"></textarea>
        </div>
        <textarea v-model="field.description" class="shadow appearance-none border rounded block py-2 px-3 w-full ms-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline order-6" placeholder="Description (optional)"></textarea>
      </div>
      <div v-else class="mb-4 flex flex-wrap gap-2 justify-between">
        <label :for="field.label" class="block text-gray-700 text-sm font-bold mb-2">{{ field.label }}</label>
        <textarea v-if="field.type === 'textarea'" v-model="field.value" :name="field.label" :class="{'border-red-500': field.error, 'shadow appearance-none border rounded w-full sm:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline': true}" @input="resetError(field)" ></textarea>
        <select v-else-if="field.type === 'select'" v-model="field.value" :name="field.label" :class="{'border-red-500': field.error, 'shadow border rounded w-full sm:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline': true}" @change="resetError(field)">
          <option v-for="option in field.options" :key="option" :value="option">{{ option }}</option>
        </select>
        <input v-else-if="field.type === 'boolean'" v-model="field.value" :name="field.label" type="checkbox" @change="resetError(field)" />
        <input v-else v-model="field.value" :type="field.type" :name="field.label" :class="{'border-red-500': field.error, 'shadow appearance-none border rounded w-full sm:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline': true}" @input="resetError(field)" />
        <p v-if="field.description" class="text-gray-600 w-full text-sm mt-2">{{ field.description }}</p>
      </div>
    </div>
    <button v-if="isEditing" class="mt-4 px-4 pt-1 pb-2 bg-[#0000ff] hover:bg-blue-400 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue text-white flex mx-auto text-3xl leading-tight" @click="addInput">+</button>
  </div>
  <div class="mt-4 flex items-center mb-14 flex-wrap">
    <span class="max-w-full overflow-anywhere">{{ concatenatedValues ? encodeURIComponent(concatenatedValues) : 'Fill in all fields' }}</span>
    <button class="px-3 py-3 underline coursor-pointer inline-flex ms-auto" @click="copyToClipboard">Copy to clipboard</button>
  </div>
</template>

<script>
export default {
  name: 'DynamicFields',
  props: {
    fields: {
      type: Array,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    },
  },
  emits: ['add-field', 'delete-field', 'move-field'], 
  computed: {
    sortedFields() {
      return [...this.fields].sort((a, b) => a.order - b.order)
    },
    
    concatenatedValues() {
      return this.fields.reduce((result, field) => {
        let addValue =''
        if (field.value) {
          addValue = field.type === 'boolean' ? field.label : field.value
          return result + (result ? field.separator ? field.separator : '-' : '') + addValue
        }
        return result
      }, '').replace(/ /g, '_')
    }
  },
  methods: {
    addInput() {
      const newField = {
        type: 'text',
        id: this.fields.length + 1,
        label: `New Field ${this.fields.length + 1}`,
        value: '',
        order: this.fields.length + 1
      };
      this.$emit('add-field', newField);
    },
    async copyToClipboard() {
      const allFieldsHaveValues = this.fields.every(field => field.value)
      if (allFieldsHaveValues) {
        try {
          await navigator.clipboard.writeText(encodeURIComponent(this.concatenatedValues))
        } catch (err) {
          console.error('Failed to copy text: ', err)
        }
      } else {
        this.fields.forEach(field => {
          if (!field.value) {
            field.error = true
          }
        })
      }
    },
    resetError(field) {
      if (field.error) {
        field.error = false
      }
    },
  }
}
</script>

<style>
.overflow-anywhere {
  overflow-wrap: anywhere;
}
@media screen and (min-width: 480px) {
  .dynamic-fields {
    min-width: 480px;
  }
}
@media screen and (min-width: 640px) {
  .dynamic-fields {
    min-width: 640px;
  }
}
</style>