import { createApp } from 'vue'
import Mikk_the_Name_Maker from './Mikk_the_Name_Maker.vue'
import '../assets/tailwind.css'
import firebaseAps from './firebase.js'  // importing Firebase configuration
import { getAuth } from "firebase/auth";

// Get the Firebase auth instance
const authMTNM = getAuth(firebaseAps.firebaseAppMTNM),
authFW = getAuth(firebaseAps.firebaseAppFW)

const app = createApp(Mikk_the_Name_Maker)
app.config.globalProperties.$firebase = {
    appMTNM: firebaseAps.firebaseAppMTNM,
    appFW: firebaseAps.firebaseAppFW,
    authMTNM: authMTNM,
    authFW: authFW
}
app.mount('#app')
